import React, { useState, useEffect } from 'react';
import './Sidebar.css';
import { useSidebar } from './SidebarContext';
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios';
import { Link } from 'react-router-dom';
import { FaHome, FaChartBar, FaEnvelope, FaBriefcase, FaCog, FaUsers, FaCreditCard } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Sidebar = () => {
  const { collapsed, toggleSidebar } = useSidebar();
  const { t, i18n } = useTranslation(); // Hook for translation
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) return;
        const userResponse = await api.get(`/user?email=${userEmail}`);
        setUser(userResponse.data.user);
        
        // Set the language based on user's preference
        if (userResponse.data.user.language) {
          i18n.changeLanguage(userResponse.data.user.language); // Set language to user's setting
        }

        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    fetchUser();
  }, [i18n]);

  const getActiveClass = (path) => {
    return window.location.pathname === path ? 'active-menu-item' : '';
  };

  if (loading) {
    return <div>Loading...</div>; // Optionally add a loading state
  }

  return (
    <div className={`sidebar ${collapsed ? 'collapsed' : 'uncollapsed'}`}>
      <div className="sidebar-logo">
        <Link to="/dashboard">
          <img src="/logo.png" alt="Your Logo" />
        </Link>
      </div>
      <div className="hamburger" onClick={toggleSidebar}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>

      <ul className="sidebar-menu">
        <li className={getActiveClass('/')}>
          <Link to="/"><FaHome className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.dashboard')}</b></span>}</Link>
        </li>
        <li className={getActiveClass('/campaigns')}>
          <Link to="/campaigns"><FaChartBar className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.campaigns')}</b></span>}</Link>
        </li>
        <li className={getActiveClass('/messages')}>
          <Link to="/messages"><FaEnvelope className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.inbox')}</b></span>}</Link>
        </li>
        <li className={getActiveClass('/leads')}>
          <Link to="/leads"><FaBriefcase className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.leads')}</b></span>}</Link>
        </li>
        <li className={getActiveClass('/settings')}>
          <Link to="/settings"><FaCog className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.settings')}</b></span>}</Link>
        </li>
        {user?.role === 'Admin' && user?.subscriptionType === 'Team' && (
          <li className={getActiveClass('/team')}>
            <Link to="/team"><FaUsers className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.team')}</b></span>}</Link>
          </li>
        )}
        {user?.role === 'Admin' && (
          <li className={getActiveClass('/subscription')}>
            <Link to="/subscription"><FaCreditCard className="menu-icon" /> {!collapsed && <span><b>{t('sidebar.billing')}</b></span>}</Link>
          </li>
        )}
      </ul>
    </div>
  );
};

export default Sidebar;
