// src/components/SubscriptionRedirect.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom'; 
import Userfront from '@userfront/toolkit/react';
import api from './../api/axios'; // Import your axios instance

const SubscriptionRedirect = () => {
  const [loading, setLoading] = useState(true);
  const [trialStatus, setTrialStatus] = useState('');
  const navigate = useNavigate(); // Correct usage
  const location = useLocation(); // Properly use the hook to get the location object


  useEffect(() => {
    const checkUserStatus = async () => {
      try {

        if (location.pathname === '/login') {
          return;
        }

        // Log user object for debugging purposes
        console.log(Userfront.user);

        const currentUser = Userfront.user.email; // Adjust as needed to get the current user's email
        
        const userResponse = await api.get(`/user?email=${currentUser}`);
        
        const subscriptionType = userResponse.subscriptionType;
        const trialPeriodEnd = new Date(userResponse.trialPeriodEnd); // Ensure this is a valid date

        // Get the current date
        const currentDate = new Date();

        // Check if the user is not confirmed and redirect to confirmation page
        if (!Userfront.user.isConfirmed) {
          navigate(`/confirmation?email=${encodeURIComponent(currentUser)}`);
        }

        // Check if the user is on a trial and the trial has expired
        if (subscriptionType === 'trial' && currentDate > trialPeriodEnd) {
          navigate('/subscription');
        }
      } catch (error) {
        console.error('Error checking user status:', error);
      }
    };

    checkUserStatus();
  }, [navigate]);

  if (loading) {
  //  return <div>Loading...</div>;
  }

  return null;
};

export default SubscriptionRedirect;
