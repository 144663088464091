import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Button } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import placeholderImage from './../assets/placeholder-image.png';
import './Navbar.css';
import Userfront from "@userfront/toolkit/react";
import api from './../api/axios';
import {
  FaHome,
  FaChartBar,
  FaEnvelope,
  FaCog,
  FaUsers,
  FaCreditCard,
  FaBriefcase,
  FaSignOutAlt,
  FaChevronDown,
  FaQuestion
} from 'react-icons/fa';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const NavBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const currentUser = Userfront.user?.email;

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userEmail = Userfront.user.email;
        if (!userEmail) {
          console.error('User email is not available.');
          setLoading(false);
          return;
        }

        const userResponse = await api.get(`/user?email=${userEmail}`);
        const currentUser = userResponse.data.user;
        setUser(currentUser);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user:', error);
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  const isAdmin = user?.role === 'Admin';
  const hasTeamSubscription = user?.subscriptionType === 'Team';

  const handleLogout = async () => {
    try {
      await api.post('/logout');
      await Userfront.logout();
      localStorage.removeItem('authToken');
      sessionStorage.removeItem('userData');
      navigate('/login');
    } catch (error) {
      console.error('Error during logout:', error);
    }
  };

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" className='custom-tooltip' {...props}>
      {t('navbar.help')}
    </Tooltip>
  );

  const profileImageUrl = user?.profileImageUrl || Userfront.user?.image || placeholderImage;

  const calculateRemainingDays = () => {
    if (user?.subscriptionType === 'trial' && user?.trialPeriodEnd) {
      const trialEndDate = new Date(user.trialPeriodEnd);
      const currentDate = new Date();
      const timeDifference = trialEndDate - currentDate;
      return Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    }
    return null;
  };

  const remainingDays = calculateRemainingDays();

  return (
    <div>
      <Navbar bg="light" expand="lg" className="topNavBar">
        <Navbar.Brand href="/dashboard" className="d-lg-none">
          <img src="/logo.png" alt="Your Logo" className="logo-mobile" />
        </Navbar.Brand>

        {user?.subscriptionType === 'trial' && remainingDays !== null && remainingDays > 0 && (
          <div className="trial-notification d-flex align-items-center me-3">
            <span className="me-2">

              {remainingDays === 1 && (
                <div>

                
                {t('navbar.trialEndsIn', { count: remainingDays })}

                </div>  
              )}

              {remainingDays > 1 && (
                <div>

                
                {t('navbar.trialEndsIn_plural', { count: remainingDays })}

                </div>  
              )}

            </span>
            <Link to="/subscription">
              <Button variant="primary">
                {t('navbar.upgradeNow')}
              </Button>
            </Link>
          </div>
        )}

        <Nav className="ms-auto topNavBarRight d-flex align-items-center">
          <div className="d-flex align-items-center">
            <OverlayTrigger placement="left" overlay={renderTooltip}>
              <div className="support-icon-wrapper flex items-center justify-center rounded-full bg-meta-2 dark:bg-meta-4 me-3">
                <a href='https://leadgenflow.freshdesk.com' target="_blank" rel="noopener noreferrer">
                  <FaQuestion className="support-icon" />
                </a>
              </div>
            </OverlayTrigger>

            <NavDropdown
              title={
                <div className="user-info d-flex align-items-center">
                  <div className="user-details">
                    <div className="user-name">{user?.name}</div>
                    <div className="user-role stats-span font-medium">{user?.role}</div>
                  </div>
                  <div className="">
                    <img src={profileImageUrl} className='profile-image-navbar' alt="User" />
                  </div>
                  <div className="dropdown-icon">
                    <FaChevronDown className="custom-caret" />
                  </div>
                </div>
              }
              id="basic-nav-dropdown"
              className="custom-dropdown no-caret"
            >
              <NavDropdown.Item as={Link} to="/">
                <FaHome className="menu-icon menu-icon-blue" /> {t('navbar.dashboard')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/campaigns">
                <FaChartBar className="menu-icon menu-icon-blue" /> {t('navbar.campaigns')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/messages">
                <FaEnvelope className="menu-icon menu-icon-blue" /> {t('navbar.messages')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/leads">
                <FaBriefcase className="menu-icon menu-icon-blue" /> {t('navbar.leads')}
              </NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/settings">
                <FaCog className="menu-icon menu-icon-blue" /> {t('navbar.settings')}
              </NavDropdown.Item>
              {isAdmin && hasTeamSubscription && (
                <NavDropdown.Item as={Link} to="/team">
                  <FaUsers className="menu-icon menu-icon-blue" /> {t('navbar.team')}
                </NavDropdown.Item>
              )}
              {isAdmin && (
                <NavDropdown.Item as={Link} to="/subscription">
                  <FaCreditCard className="menu-icon menu-icon-blue" /> {t('navbar.billing')}
                </NavDropdown.Item>
              )}
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
                <FaSignOutAlt className="menu-icon menu-icon-blue" /> {t('navbar.logout')}
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </Nav>
      </Navbar>
    </div>
  );
};

export default NavBar;
