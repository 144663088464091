import React, { useEffect } from 'react';
import './Alert.css';

const AlertComponent = ({ type, message, onClose, duration = 5000 }) => {
  useEffect(() => {
    if (!message) return; // Don't set a timer if there's no message

    const timer = setTimeout(() => {
      onClose();
    }, duration);

    return () => clearTimeout(timer); // Clear the timer on component unmount
  }, [message, duration, onClose]);

  if (!message) return null;

  return (
    <div className={`custom-alert alert-${type}`}>
      <span>{message}</span>
      <button className="close-button" onClick={onClose}>&times;</button>
    </div>
  );
};

export default AlertComponent;
