import React, { useState, useEffect } from 'react';
import axios from 'axios';

import api from './../api/axios';

const SlackIntegration = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [slackChannel, setSlackChannel] = useState('');
  const [logMessage, setLogMessage] = useState('');
  const SLACK_CLIENT_ID = process.env.REACT_APP_SLACK_CLIENT_ID;
  const SLACK_REDIRECT_URI = process.env.REACT_APP_SLACK_REDIRECT_URI;
  const SLACK_OAUTH_URL = `https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=chat:write&redirect_uri=${SLACK_REDIRECT_URI}`;

  // Handle Slack OAuth callback
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    if (code) {
      // Exchange code for access token
      api.post('/integrations/openai/slack/auth', { code, redirect_uri: SLACK_REDIRECT_URI })
        .then(response => {
          const { access_token, slack_channel } = response.data;
          setAccessToken(access_token);
          setSlackChannel(slack_channel);
          setIsConnected(true);
        })
        .catch(error => {
          console.error('Slack OAuth error:', error);
        });
    }
  }, []);

  const handleConnect = () => {
    // Redirect to Slack OAuth URL
    window.location.href = SLACK_OAUTH_URL;
  };

  const handleSendMessage = () => {
    if (!accessToken || !slackChannel) {
      console.error('Slack is not connected or channel is not specified');
      return;
    }

    const messageData = {
      channel: slackChannel,
      text: `Log Message Received: ${logMessage}`,
    };

    api.post('/integrations/openai/slack/sendMessage', messageData, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response => {
      console.log('Message sent to Slack:', response.data);
      setLogMessage(''); // Clear the log message
    })
    .catch(error => {
      console.error('Error sending message to Slack:', error);
    });
  };

  return (
    <div className="slack-integration">
      <h3>Slack Integration</h3>
      {!isConnected ? (
        <div>
          <p>Connect your Slack account to send notifications.</p>
          <button onClick={handleConnect} className="btn btn-primary">
            Connect to Slack
          </button>
        </div>
      ) : (
        <div>
          <p>Slack is connected. Notifications will be sent to the configured channel.</p>
          <div>
            <input
              type="text"
              placeholder="Enter log message"
              value={logMessage}
              onChange={(e) => setLogMessage(e.target.value)}
            />
            <button onClick={handleSendMessage} className="btn btn-success">
              Send Log Message to Slack
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SlackIntegration;
