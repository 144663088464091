import React, { useState, useEffect } from 'react';
import Userfront from '@userfront/toolkit/react';
import './Team.css';
import Sidebar from './../../components/Sidebar';
import NavBar from './../../components/Navbar';
import AlertComponent from './../../components/Alert';
import { Modal, Button, Form, Spinner, Alert, Dropdown } from 'react-bootstrap';
import api from './../../api/axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

import { useTranslation } from 'react-i18next';
import i18n from './../../i18n'; // Import your i18n configuration

const Team = () => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteData, setInviteData] = useState({ name: '', email: '' });
  const [availableSeats, setAvailableSeats] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false); // State for delete confirmation modal
  const [userToDelete, setUserToDelete] = useState(null); // User selected for deletion


  useEffect(() => {
    const fetchData = async () => {
      await fetchOrganizationUsers();
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (users.length > 0) {
      updateAvailableSeats();
    }
  }, [users]);

  useEffect(() => {
    if (message) {
      const timer = setTimeout(() => {
        setMessage(null);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const fetchOrganizationUsers = async () => {
    try {

      if (!Userfront.user || !Userfront.user.email) {
        console.error('User is not authenticated or user data is not available.');
        return;
      }

      const email = Userfront.user.email;
      const response = await api.get(`/organization-users`, { params: { email } });
      const users = response.data.users;
      setUsers(users);

      const currentUser = Userfront.user;
      const isAdminUser = users.some(user => String(user.email) === String(currentUser.email) && user.role === 'Admin');
      setIsAdmin(isAdminUser);

      // Fetch user settings including language preference
      const userResponse = await api.get(`/user?email=${currentUser.email}`);
      const userSettings = userResponse.data.user;

      // Set the language based on the user setting
      if (userSettings.language) {
        i18n.changeLanguage(userSettings.language);
      } else {
        i18n.changeLanguage('en'); // Fallback to English if no preference
      }

    } catch (error) {
      console.error('Error fetching organization users:', error);
    }
  };

  const updateAvailableSeats = async () => {
    try {
      const email = Userfront.user.email;
      const subscriptionsResponse = await api.get(`/subscriptions?email=${email}`);
      const activeSubscriptions = subscriptionsResponse.data.subscriptions;

      const totalSeatsBought = activeSubscriptions.reduce((total, sub) => total + sub.quantity, 0);
      const seatsInUse = users.filter(user => user.subscriptionStatus === 'Active').length;
      const available = Math.max(0, totalSeatsBought - seatsInUse);
      setAvailableSeats(available);
    } catch (error) {
      console.error('Error calculating available seats:', error);
      setAvailableSeats(0);
    }
  };

  const handleInvite = async () => {
    setLoading(true);
    setMessage(null);
    try {
      const currentUserEmail = Userfront.user.email;
      const userResponse = await api.get(`/user?email=${currentUserEmail}`);
      const currentUser = userResponse.data.user;

      await api.post('/invite-user', {
        email: inviteData.email,
        name: inviteData.name,
        role: 'Member',
        organizationId: currentUser.organizationId,
      });

      setShowInviteModal(false);
      setMessage({ type: 'success', text: t('team.user.invitedSuccessfully') });
    } catch (error) {
      console.error('Error inviting user:', error);
      setMessage({ type: 'danger', text: t('team.user.invitationFailed') });
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmStatus = async () => {
    if (!selectedUser) {
      setMessage({ type: 'danger', text: t('team.user.noUserSelected') });
      return;
    }

    if (availableSeats <= 0 && selectedStatus === 'Active') {
      setMessage({ type: 'danger', text: t('team.user.noSeatsLeft') });
      return;
    }

    setLoading(true);
    setMessage(null);
    try {
      await api.put('/update-subscription-status', {
        email: selectedUser,
        subscriptionStatus: selectedStatus,
      });

      setMessage({ type: 'success', text: t('team.user.subscriptionStatusUpdated') });
      setSelectedUser(null);
      setSelectedStatus('');
      await fetchOrganizationUsers();
    } catch (error) {
      console.error('Error updating subscription status:', error);
      setMessage({ type: 'danger', text: t('team.user.updateFailed') });
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    setInviteData({
      ...inviteData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSelectUser = (email, currentStatus) => {
    setSelectedUser(email);
    setSelectedStatus(currentStatus === 'Active' ? 'Inactive' : 'Active');
  };

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
  };

  const handleCloseAlert = () => {
    setMessage(null);
  };





  const handleDeleteUser = async () => {
    if (!userToDelete) {
      setMessage({ type: 'danger', text: t('team.user.noUserSelected') });
      return;
    }

    console.log("Attempting to delete user: " + userToDelete.email);
    setLoading(true);
    setMessage(null);

    try {
      // Fetch the selected user data (including userId)
      const response = await api.get(`/user?email=${userToDelete.email}`);
      const selectedUser = response.data.user; // Assuming the API returns the user object directly

      // Ensure userId exists in the fetched data
      if (!selectedUser || !selectedUser.userId) {
        setMessage({ type: 'danger', text: t('team.user.noUserIdFound') });
        setLoading(false);
        return;
      }

      // Delete the user using email and userId
      await api.delete(`/user?email=${userToDelete.email}`);
      setMessage({ type: 'success', text: t('team.user.deletedSuccessfully') });
      setShowDeleteModal(false);
      setUserToDelete(null);
      await fetchOrganizationUsers(); // Refresh users list
    } catch (error) {
      console.error('Error deleting user:', error);
      setMessage({ type: 'danger', text: t('team.user.deleteFailed') });
    } finally {
      setLoading(false);
    }
};


  const handleShowDeleteModal = (user) => {
    setUserToDelete(user);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setUserToDelete(null);
  };


  const [typedName, setTypedName] = useState('');

  const handleInputChange = (e) => {
    setTypedName(e.target.value);
  };

  const isDeleteDisabled = typedName !== (userToDelete ? userToDelete.name : '');






  return (
    <div className="dashboard">
      <Sidebar />
      <div className="container">
        <NavBar />
        <div className="row page-row">
          <div className="col-md-12">
            <div className='activities-widget'>
            {message && (
              <AlertComponent
                type={message.type}
                message={message.text}
                onClose={handleCloseAlert}
              />
            )}

              {loading && (
                <div className="loading-spinner">
                  <Spinner animation="border" />
                </div>
              )}

              <div className="header-container">
                <h2>{t('team.title')}</h2>

                {isAdmin && availableSeats !== null && (
                  <div className='header-info'>
                    <div className="available-seats-container">
                      <span className="available-seats-label">{t('team.availableSeats')}:</span>
                      <span className={`available-seats-text ${availableSeats === 0 ? 'text-danger' : 'text-success'}`}>
                        {availableSeats}
                      </span>
                    </div>
                    {availableSeats > 0 && (
                      <button onClick={() => setShowInviteModal(true)} className="btn btn-primary">
                        {t('team.inviteUser')}
                      </button>
                    )}
                  </div>
                )}
              </div>

              <div className='table-responsive'>
                <table className='table'>
                  <thead>
                    <tr>
                      <th>{t('team.name')}</th>
                      <th>{t('team.email')}</th>
                      <th>{t('team.role')}</th>
                      <th>{t('team.status')}</th>
                      {isAdmin && <th>{t('team.actions')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.userId}>
                        <td>{user.name}</td>
                        <td>{user.email}</td>
                        <td>{user.role}</td>
                        <td>
                          <div
                            className={`status-label-container ${
                              user.subscriptionStatus === 'Active' ? 'status-label-active' : 'status-label-inactive'
                            }`}
                          >
                            {user.subscriptionStatus === 'Active' ? t('team.active') : t('team.inactive')}
                          </div>
                        </td>
                        {isAdmin && (
                          <td>
                          {selectedUser === user.email ? (
                            <>
                              <Form.Select
                                value={selectedStatus}
                                onChange={handleStatusChange}
                                style={{ marginBottom: '10px' }}
                              >
                                <option value="Active">{t('team.active')}</option>
                                <option value="Inactive">{t('team.inactive')}</option>
                              </Form.Select>
                              <Button
                                onClick={handleConfirmStatus}
                                className="btn btn-primary"
                                disabled={availableSeats <= 0 && selectedStatus === 'Active'}
                              >
                                {t('team.confirm')}
                              </Button>
                            </>
                          ) : (
                            <div>
                              <Dropdown>
                                <Dropdown.Toggle variant="link" id="dropdown-custom-components">
                                  <FontAwesomeIcon icon={faEllipsisV} />
                                </Dropdown.Toggle>
                    
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    as="button"
                                    onClick={() => handleSelectUser(user.email, user.subscriptionStatus)}
                                  >
                                    <FaEdit style={{ marginRight: '8px' }} />
                                    {t('team.edit')}
                                  </Dropdown.Item>
                    
                                  {user.subscriptionStatus === 'Inactive' && users.length > 1 && (
                                    <Dropdown.Item
                                      as="button"
                                      onClick={() => handleShowDeleteModal(user)}
                                      className="text-danger"
                                    >
                                      <FaTrash style={{ marginRight: '8px' }} />
                                      {t('team.delete')}
                                    </Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          )}
                        </td>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <Modal show={showInviteModal} onHide={() => setShowInviteModal(false)}>
              <Modal.Header closeButton>
                <Modal.Title>{t('team.inviteUser')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="inviteName">
                    <Form.Label>{t('team.name')}</Form.Label>
                    <Form.Control
                      type="text"
                      name="name"
                      value={inviteData.name}
                      onChange={handleChange}
                      placeholder={t('team.enterName')}
                    />
                  </Form.Group>
                  <Form.Group controlId="inviteEmail">
                    <Form.Label>{t('team.email')}</Form.Label>
                    <Form.Control
                      type="email"
                      name="email"
                      value={inviteData.email}
                      onChange={handleChange}
                      placeholder={t('team.enterEmail')}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowInviteModal(false)}>
                  {t('team.cancel')}
                </Button>
                <Button variant="primary" onClick={handleInvite} disabled={loading}>
                  {loading ? <Spinner as="span" animation="border" size="sm" /> : t('team.sendInvite')}
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
              <Modal.Header closeButton>
                <Modal.Title>{t('team.confirmDelete')}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <p>
                  {t('team.deleteConfirmation', { userName: userToDelete ? userToDelete.name : '' })}
                </p>
                <p className="text-danger">
                  {t('team.deleteWarning')}
                </p>
                <Form.Group controlId="formDeleteUserName">
                  <Form.Label>{t('team.typeNameToDelete', { userName: userToDelete ? userToDelete.name : '' })}</Form.Label>
                  <Form.Control
                    type="text"
                    value={typedName}
                    onChange={handleInputChange}
                    placeholder={t('team.enterNameDelete', { userName: userToDelete ? userToDelete.name : '' })}
                  />
                </Form.Group>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseDeleteModal}>
                  {t('team.cancel')}
                </Button>
                <Button variant="danger" onClick={handleDeleteUser} disabled={isDeleteDisabled}>
                  {t('team.delete')}
                </Button>
              </Modal.Footer>
            </Modal>

            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Team;
