import React from 'react';
import { useTranslation } from 'react-i18next';
import countries from './../../components/Countries';
import './InvoicingDetailsForm.css';

const InvoicingDetailsForm = ({ invoicingDetails, setInvoicingDetails, handleSaveInvoicingDetails, error }) => {
  const { t } = useTranslation();

  const handleInvoicingChange = (e) => {
    const { name, value } = e.target;
    setInvoicingDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
    }));
  };

  const handleCountryChange = (e) => {
    const { name, value } = e.target;
    setInvoicingDetails(prevDetails => ({
      ...prevDetails,
      [name]: value,
      state: value === 'United States' ? prevDetails.state : '', // Clear state if country is not USA
    }));
  };

  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      <div className="invoicing-form">
        
        {/* Company Name */}
        <div className="form-group">
          <label htmlFor="companyName">{t('subscription.companyName')}</label>
          <input
            className='form-control'
            type="text"
            id="companyName"
            name="companyName"
            value={invoicingDetails.companyName || ''}
            onChange={handleInvoicingChange}
          />
        </div>

        {/* Country */}
        <div className="form-group">
          <label htmlFor="country">{t('subscription.country')}</label>
          <select
            className='form-control'
            id="country"
            name="country"
            value={invoicingDetails.country || ''}
            onChange={handleCountryChange}
          >
            <option value="">{t('subscription.selectCountry')}</option>
            {countries.map((country) => (
              <option key={country.code} value={country.name}>
                {country.name}
              </option>
            ))}
          </select>
        </div>

        {/* Address Lines in a row */}
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="addressLine1">{t('subscription.address')}</label>
            <input
              className='form-control'
              type="text"
              id="addressLine1"
              name="addressLine1"
              value={invoicingDetails.addressLine1 || ''}
              onChange={handleInvoicingChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="addressLine2">{t('subscription.addressLine2')}</label>
            <input
              className='form-control'
              type="text"
              id="addressLine2"
              name="addressLine2"
              placeholder={t('subscription.optional')}
              value={invoicingDetails.addressLine2 || ''}
              onChange={handleInvoicingChange}
            />
          </div>
        </div>

        {/* City and Postal Code in a row */}
        <div className="row">
          <div className="form-group col-md-6">
            <label htmlFor="city">{t('subscription.city')}</label>
            <input
              className='form-control'
              type="text"
              id="city"
              name="city"
              value={invoicingDetails.city || ''}
              onChange={handleInvoicingChange}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="postalCode">{t('subscription.postalCode')}</label>
            <input
              className='form-control'
              type="text"
              id="postalCode"
              name="postalCode"
              value={invoicingDetails.postalCode || ''}
              onChange={handleInvoicingChange}
            />
          </div>
        </div>

        {/* State (only visible if country is United States) */}
        {invoicingDetails.country === 'United States' && (
          <div className="form-group">
            <label htmlFor="state">{t('subscription.state')}</label>
            <input
              className='form-control'
              type="text"
              id="state"
              name="state"
              value={invoicingDetails.state || ''}
              onChange={handleInvoicingChange}
            />
          </div>
        )}

        {/* VAT Number */}
        <div className="form-group">
          <label htmlFor="vatNumber">{t('subscription.vatNumber')}</label>
          <input
            className='form-control'
            type="text"
            id="vatNumber"
            name="vatNumber"
            value={invoicingDetails.vatNumber || ''}
            onChange={handleInvoicingChange}
          />
        </div>
      </div>

      <button className="btn btn-primary" onClick={handleSaveInvoicingDetails}>
        {t('subscription.saveDetails')}
      </button>
    </div>
  );
};

export default InvoicingDetailsForm;
