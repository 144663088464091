import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Form, Button, Dropdown } from 'react-bootstrap';
import ReactFlow, {
  ReactFlowProvider,
  useNodesState,
  useEdgesState,
  addEdge,
  useReactFlow,
  Panel,
  applyNodeChanges,
  Background, 
  BackgroundVariant,
} from 'reactflow';
import 'reactflow/dist/style.css';
import api from './../api/axios'; // Import your axios instance
import './TemplateBuilder.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

const getNodeId = () => `node_${+new Date()}_${Math.random()}`;
const getEdgeId = () => `edge_${+new Date()}_${Math.random()}`;

const NODE_WIDTH = 200; // Adjust this to the actual width of your nodes
const NODE_HEIGHT = 35; // Adjust based on your node's actual height

const initialNodes = [
  { 
    id: 'start', 
    data: { label: 'Start' }, 
    position: { x: (window.innerWidth / 2) - (NODE_WIDTH / 2), y: 50 }, // Centered horizontally
    deletable: false, 
    className: 'node-start' 
  },
];

const CustomNodeComponent = ({ data }) => {
  return (
    <div className={`node-content ${data.className}`}>
      {data.icon && <i className={`fa ${data.icon}`} style={{ marginRight: '5px' }}></i>} {/* Render icon if present */}
      <span>{data.label}</span>
    </div>
  );
};

const nodeTypes = {
  'customNode': CustomNodeComponent,
};

const initialEdges = [];

const SaveRestore = ({ id }) => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [rfInstance, setRfInstance] = useState(null);
  const [selectedNode, setSelectedNode] = useState(null);
  // const [nodeSettings, setNodeSettings] = useState({});
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { setViewport } = useReactFlow();
  const [isSendMessageDisabled, setIsSendMessageDisabled] = useState(true); // Initially disabled

  const [nodeSettings, setNodeSettings] = useState({
    duration: '',
    timeUnit: 'minutes', // New state for time unit
  });
  
  const [showPanels, setShowPanels] = useState(false);

  const checkSendMessageButtonStatus = useCallback(() => {
    if (!selectedNode) {
      setIsSendMessageDisabled(true);
      return;
    }
  
    // Check if we are directly after an "Accepted" node or if there's an "Accepted" node somewhere in the path
    const canSendMessage = canAddMessageDirectlyAfterAccepted(selectedNode) || hasAcceptedInPath(selectedNode.id);
    setIsSendMessageDisabled(!canSendMessage);
  }, [selectedNode]);
  
  useEffect(() => {
    checkSendMessageButtonStatus();
  }, [selectedNode, checkSendMessageButtonStatus]);

  const onConnect = useCallback((params) => {
    const existingEdge = edges.find((edge) => edge.source === params.source || edge.target === params.target);
    if (!existingEdge) {
      setEdges((eds) => addEdge(params, eds));
    }
  }, [setEdges, edges]);

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
    setNodeSettings(node.data || {});
    setShowSuccessMessage(false);
    
    // Show the panels regardless of whether the node has a next step
    setShowPanels(true);
  }, []);

  const connectionRequestExists = useCallback(() => {
    return nodes.some((node) => node.data.label === 'Send invite');
  }, [nodes]);
  
  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      saveConfiguredWorkflow({ workflow: flow, id });
    }
  }, [rfInstance, id]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      const savedWorkflow = await getConfiguredWorkflow(id);
      if (!savedWorkflow) {
        console.log('No saved workflow found in the backend.');
        return;
      }
      try {
        const { viewport, nodes: savedNodes, edges: savedEdges } = savedWorkflow;
        if (viewport) {
          const { x = 0, y = 0, zoom = 1 } = viewport;
          setViewport({ x, y, zoom });
        }
        if (savedNodes) {
          setNodes(savedNodes);
        }
        if (savedEdges) {
          setEdges(savedEdges);
        }
      } catch (error) {
        console.error('Error parsing saved workflow:', error);
      }
    };
    restoreFlow();

    //added onSave here for autosave 
    onSave();

  }, [setNodes, setEdges, setViewport, id]);

  const addNodeBelowSelected = (newNode) => {
    if (!selectedNode) return;
  
    // Calculate the Y position directly below the selected node
    const newYPosition = selectedNode.position.y + NODE_HEIGHT + 50; // Maintain spacing
  
    // Adjust X position based on the selected node type
    const xOffset = selectedNode.id === 'start' ? -50 : 0; // Center horizontally below the selected node
  
    const positionedNode = {
      ...newNode,
      position: { x: selectedNode.position.x + xOffset, y: newYPosition }, // Maintain the same x position for the start node
    };
  
    setNodes((nds) => nds.concat(positionedNode));
    setEdges((eds) => eds.concat({ id: getEdgeId(), source: selectedNode.id, target: positionedNode.id }));
  
    setSelectedNode(positionedNode); // Automatically select the new node
  //  setShowActionOptions(null); // Hide actions after adding a node
  };


  const addChildNodesForConnectionRequest = (parentNode) => {
    if (!parentNode) return; // Ensure parentNode is defined
  
    const acceptedNode = {
      id: getNodeId(),
      data: { label: 'Accepted', icon: 'fa-check-circle' },
      className: 'node-accepted',
      position: { x: parentNode.position.x + 250, y: parentNode.position.y + 100 }, // Adjust to be below
    };
  
    const notAcceptedNode = {
      id: getNodeId(),
      data: { label: 'Not Accepted', icon: 'fa-times-circle' },
      className: 'node-not-accepted',
      position: { x: parentNode.position.x - 250, y: parentNode.position.y + 100 }, // Adjust to be below
    };
  
    // Add a Timer Node below the Not Accepted Node
    const timerNode = {
      id: getNodeId(),
      data: { label: 'Timer', icon: 'fa-clock', type: 'timer' }, // Default duration
      className: 'node-timer',
      position: { x: notAcceptedNode.position.x, y: notAcceptedNode.position.y + 100 }, // Place below Not Accepted
    };
  
    setNodes((nds) => nds.concat(acceptedNode, notAcceptedNode, timerNode));
    setEdges((eds) => eds.concat(
      { id: getEdgeId(), source: parentNode.id, target: acceptedNode.id },
      { id: getEdgeId(), source: parentNode.id, target: notAcceptedNode.id },
      { id: getEdgeId(), source: notAcceptedNode.id, target: timerNode.id } // Link Timer to Not Accepted
    ));
  };
  
  const addChildNodesForSendMessage = (parentNode) => {
    if (!parentNode) return; // Ensure parentNode is defined
  
    // Create Response and No Response nodes
    const responseNode = {
      id: getNodeId(),
      data: { label: 'Response' },
      className: 'node-response',
      position: { x: parentNode.position.x + 250, y: parentNode.position.y + 80 }, // Positioned to the right
    };
  
    const noResponseNode = {
      id: getNodeId(),
      data: { label: 'No Response' },
      className: 'node-no-response',
      position: { x: parentNode.position.x - 250, y: parentNode.position.y + 80 }, // Positioned to the left
    };
  
    // Create a Timer node below the No Response node
    const timerNode = {
      id: getNodeId(),
      data: { label: 'Timer', type: 'timer' },
      className: 'node-timer',
      position: { x: noResponseNode.position.x, y: noResponseNode.position.y + 100 }, // Positioned directly below No Response node
    };
  
    // Update nodes and edges
    setNodes((nds) => nds.concat(responseNode, noResponseNode, timerNode));
    setEdges((eds) => eds.concat(
      { id: getEdgeId(), source: parentNode.id, target: responseNode.id },
      { id: getEdgeId(), source: parentNode.id, target: noResponseNode.id },
      { id: getEdgeId(), source: noResponseNode.id, target: timerNode.id } // Edge from No Response to Timer
    ));
  };
  
  const onAddConnectionRequest = useCallback(() => {
    if (!selectedNode) return; // Ensure there is a selected node
    
    // Check if a "Connection request" node already exists in the flow
    const connectionRequestExists = nodes.some((node) => node.data.label === 'Send invite');
    if (connectionRequestExists) {
      alert('A "Connection request" node is already present in the flow.');
      return; // Prevent adding a new "Connection request" node
    }
  
    const newConnectionNode = {
      id: getNodeId(),
      data: { label: 'Send invite' },
      position: {
        x: selectedNode.position.x, // Use the selected node's x position
        y: selectedNode.position.y + NODE_HEIGHT + 40, // Place it below the selected node
      },
      className: 'node-connection-request',
    };
  
    addNodeBelowSelected(newConnectionNode);
    addChildNodesForConnectionRequest(newConnectionNode); // Ensure this is the new node created
    setShowPanels(false);

    onSave();

  }, [addNodeBelowSelected, selectedNode, nodes]);
  
  const onAddProfileView = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'Profile view', icon: 'fa-user' },
      className: 'node-profile-view',
    };
    addNodeBelowSelected(newNode);
    setShowPanels(false);

    onSave();

  }, [addNodeBelowSelected]);

  const onAddPauseFlow = useCallback(() => {
    const newNode = {
      id: getNodeId(),
      data: { label: 'Timer', icon: 'fa-clock' },
      className: 'node-timer',
    };
    addNodeBelowSelected(newNode);
    setShowPanels(false);

    onSave();

  }, [addNodeBelowSelected]);

  const onAddEndFlow = useCallback(() => {
    if (!selectedNode) return;
  
    const lastNode = nodes[nodes.length - 1]; // Get the last node
    const newNode = {
      id: getNodeId(),
      data: { label: 'End flow' },
      position: {
        x: lastNode.position.x, // Use the same x position as the last node
        y: lastNode.position.y + NODE_HEIGHT + 40, // Place it directly below the last node
      },
      className: 'node-end',
    };
  
    addNodeBelowSelected(newNode); // Add the node below the last node
    setShowPanels(false);

    onSave();

  }, [addNodeBelowSelected, nodes, selectedNode]);
  

  const canAddMessageDirectlyAfterAccepted = (selectedNode) => {
    return selectedNode.data.label === 'Accepted';
  };

  const hasAcceptedInPath = (nodeId) => {
    let currentNodeId = nodeId;
    while (true) {
      const incomingEdge = edges.find(edge => edge.target === currentNodeId);
      if (!incomingEdge) return false; // No more nodes to traverse, stop
      const sourceNode = nodes.find(node => node.id === incomingEdge.source);
      if (sourceNode && sourceNode.data.label === 'Accepted') {
        return true; // Found an "Accepted" node in the path
      }
      currentNodeId = incomingEdge.source; // Continue traversing upwards
    }
  };

  const onAddSendMessage = useCallback(() => {
    if (!selectedNode) return; // Ensure there is a selected node

    // Check if we are directly after an "Accepted" node or if there's an "Accepted" node somewhere in the path
    if (!canAddMessageDirectlyAfterAccepted(selectedNode) && !hasAcceptedInPath(selectedNode.id)) {
      alert('You can only add a "Send message" node if there is an "Accepted" node in the flow.');
      return; // Prevent adding the node if no "Accepted" node is found
    }

    const newMessageNode = {
      id: getNodeId(),
      data: { label: 'Message' },
      position: { 
        x: selectedNode.position.x, // Use the selected node's x position
        y: selectedNode.position.y + 100 // Place it below the selected node
      },
      className: 'node-message',
    };

    addNodeBelowSelected(newMessageNode);
    addChildNodesForSendMessage(newMessageNode); // Ensure this is the new node created
    setShowPanels(false);

    onSave();

  }, [addNodeBelowSelected, selectedNode, nodes, edges]);


  useEffect(() => {
    onRestore();
  }, [onRestore]);

  const saveConfiguredWorkflow = async ({ workflow, id }) => {
    try {
      await api.post(`/campaigns/${id}/configure-workflow`, {
        configuredWorkflow: workflow,
      });
      console.log('Configured workflow saved to the campaign:');
    } catch (error) {
      console.error('Error saving configured workflow:', error.response.data);
    }
  };

  const getConfiguredWorkflow = async (id) => {
    try {
      const response = await api.get(`/campaigns/${id}`);
      return response.data.configuredWorkflow;
    } catch (error) {
      console.error('Error fetching configured workflow:', error.response.data);
      return null;
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setNodeSettings((prevSettings) => ({
      ...prevSettings,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    setNodeSettings((prevSettings) => ({
      ...prevSettings,
      [name]: checked,
    }));
  };

  const handleSaveSettings = () => {
    const { duration, timeUnit, message, sendMessage } = nodeSettings; // Extract message and sendMessage
  
    // Convert duration to minutes
    let durationInMinutes = parseInt(duration) || 0;
    if (timeUnit === 'hours') {
        durationInMinutes *= 60;
    } else if (timeUnit === 'days') {
        durationInMinutes *= 1440; // 24 hours * 60 minutes
    }
  
    setNodes((currentNodes) =>
        currentNodes.map((node) => {
            if (node.id === selectedNode.id) {
                return {
                    ...node,
                    data: {
                        ...node.data,
                        duration: durationInMinutes, // Store duration in minutes
                        message: message || '', // Save message
                        sendMessage: sendMessage || false // Save sendMessage checkbox state
                    },
                };
            }
            return node;
        })
    );
    setShowSuccessMessage(true);
  };


  const handleInsertDynamicField = (field) => {
    setNodeSettings((prevSettings) => {
      const message = prevSettings.message || '';
      if (!message.includes(field)) {
        return {
          ...prevSettings,
          message: message + field,
        };
      }
      return prevSettings;
    });
  };

  const handleClosePanel = () => {
    setShowPanels(false);
  };

  const renderDynamicFieldsDropdown = () => (
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id="dropdown-basic">
        Add dynamic field
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={() => handleInsertDynamicField('{{name}}')}>
          First Name
        </Dropdown.Item> 
      </Dropdown.Menu>
    </Dropdown>
  );


  const renderNodeSettings = () => {
    if (!selectedNode) return null;

  const { label } = nodeSettings;

  // Check if the selected node has any outgoing edges only if selectedNode is not null
  const hasOutgoingEdges = edges.some((edge) => edge.source === selectedNode.id);

  const handleDeleteNode = () => {
    // Remove the selected node from the nodes state
    setNodes((nds) => nds.filter((node) => node.id !== selectedNode.id));
    setEdges((eds) => eds.filter((edge) => edge.source !== selectedNode.id && edge.target !== selectedNode.id)); // Remove related edges
    setSelectedNode(null); // Clear the selected node after deletion
    setShowPanels(false);

    onSave();

  };

    switch (label) {
      case 'Timer':
        return (
          <Form>
            <Form.Group controlId="timerDuration">
              <Form.Label>Set wait time</Form.Label>
              <Form.Control
                type="number"
                name="duration"
                value={nodeSettings.duration || ''}
                onChange={handleChange}
                placeholder="Enter value"
              />
            </Form.Group>
            <Form.Group controlId="timerTimeUnit">
              <Form.Label>Time Unit</Form.Label>
              <Form.Control
                as="select"
                name="timeUnit"
                value={nodeSettings.timeUnit}
                onChange={handleChange}
              >
                <option value="minutes">Minutes</option>
                <option value="hours">Hours</option>
                <option value="days">Days</option>
              </Form.Control>
            </Form.Group>
            <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
            {showSuccessMessage && <div className="success-message">Saved successfully!</div>}
            
            {!hasOutgoingEdges && ( // Show delete button only if no outgoing edges
            <Button variant="danger" onClick={handleDeleteNode} style={{ marginLeft: '10px' }}>
              Delete Node
            </Button>
          )}

          </Form>
        );

        case 'Send invite':
          return (
            <Form>
              <Form.Group controlId="sendConnectionRequestCheckbox">
                <Form.Check
                  type="checkbox"
                  label="Send message with request"
                  name="sendMessage"
                  checked={nodeSettings.sendMessage || false} // Default to false if not set
                  onChange={handleCheckboxChange} // Handle checkbox change
                />
              </Form.Group>
              {nodeSettings.sendMessage && ( // Show message input if checkbox is checked
                <Form.Group controlId="message">
                  <Form.Label>Message to send</Form.Label>
                  {renderDynamicFieldsDropdown()}
                  <Form.Control
                    as="textarea"
                    name="message"
                    value={nodeSettings.message || ''}
                    onChange={handleChange}
                  />
                </Form.Group>
              )}
              <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
              {showSuccessMessage && <div className="success-message">Saved successfully!</div>}

              {!hasOutgoingEdges && ( // Show delete button only if no outgoing edges
            <Button variant="danger" onClick={handleDeleteNode} style={{ marginLeft: '10px' }}>
              Delete Node
            </Button>
          )}

            </Form>
          );
        
      case 'Message':
        return (
          <Form>
            <Form.Group controlId="message">
              <Form.Label>Message to send</Form.Label>
              {renderDynamicFieldsDropdown()}
              <Form.Control
                as="textarea"
                name="message"
                value={nodeSettings.message || ''}
                onChange={handleChange}
              />
            </Form.Group>
            <Button variant="primary" onClick={handleSaveSettings}>Save</Button>
            {showSuccessMessage && <div className="success-message">Saved successfully!</div>}

            {!hasOutgoingEdges && ( // Show delete button only if no outgoing edges
            <Button variant="danger" onClick={handleDeleteNode} style={{ marginLeft: '10px' }}>
              Delete Node
            </Button>
          )}

          </Form>
        );
        case 'End flow':
        return (
          <div>
            <p>No action</p>

              <Button variant="danger" onClick={handleDeleteNode} style={{ marginLeft: '10px' }}>
                Delete Node
              </Button>
          </div>
        );
        default:
          // Check if selected node is the start node
          const isStartNode = selectedNode.data.label === 'Start'; // Replace 'Start Node' with the actual label or condition for your start node
          return (
            <div>
              <p>No settings available for this node.</p>
              {/* Show delete button only if no outgoing edges and not a start node */}
              {!hasOutgoingEdges && !isStartNode && (
                <Button variant="danger" onClick={handleDeleteNode} style={{ marginLeft: '10px' }}>
                  Delete Node
                </Button>
              )}
            </div>
          );
    }
  };

  // Function to check if a node has any outgoing connections
  const hasOutgoingConnections = (nodeId) =>
    edges.some(edge => edge.source === nodeId);

  return (
    <div
      className="reactflow-wrapper"
      style={{ width: '100%', height: '70vh', position: 'relative' }}
      ref={reactFlowWrapper}
    >
      <ReactFlow
        nodes={nodes}
        edges={edges}
        nodeTypes={nodeTypes}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={onNodeClick}
        onInit={setRfInstance}
        zoomOnScroll={true}
        panOnScroll={false}
        panOnDrag={true}
        zoomOnDoubleClick={false}
        minZoom={0.6}
        maxZoom={1}
        deleteKeyCode={''}
      >
        <Background color="#000" variant={BackgroundVariant.Dots} />
            
        {showPanels && (
          <Panel position="top-right" className="panel-workflow">
            <button
              onClick={handleClosePanel}
              style={{
                position: 'absolute',
                top: 10,
                right: 10,
                background: 'none',
                border: 'none',
                cursor: 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>

            {/* Check if the selected node is not the "End flow" node */}
            {!hasOutgoingConnections(selectedNode?.id) &&
              selectedNode?.data.label !== 'End flow' && (
                <div>
                  <h3>Add action</h3>
                  <NavDropdown title="Select node" id="basic-nav-dropdown">
                    <NavDropdown.Item
                      onClick={onAddConnectionRequest}
                      disabled={connectionRequestExists()}
                    >
                      Send invite
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onAddProfileView}>
                      Profile view
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item
                      onClick={onAddSendMessage}
                      disabled={isSendMessageDisabled}
                    >
                      Send message
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onAddPauseFlow}>
                      Add timer
                    </NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={onAddEndFlow}>
                      End flow
                    </NavDropdown.Item>
                  </NavDropdown>
                  <hr />
                </div>
              )}

            {selectedNode && (
              <div>
                <h3>Node settings</h3>
                {renderNodeSettings()}
              </div>
            )}
          </Panel>
        )}

        <Panel position="" className="panel-workflow">
          <Button onClick={onSave} variant="primary" style={{ marginRight: '10px' }}>
            Save workflow
          </Button>
          <Button onClick={onRestore} variant="secondary">
            Undo changes
          </Button>
        </Panel>
      </ReactFlow>
    </div>
  );
};

export default SaveRestore;