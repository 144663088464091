const countriesLocation = [
    {"id": 3758, "name": "USA", "alpha3": "USA"},
    {"id": 4479, "name": "Poland", "alpha3": "POL"},
    {"id": 4480, "name": "Netherlands", "alpha3": "NLD"},
    {"id": 5236, "name": "Brazil", "alpha3": "BRA"},
    {"id": 5389, "name": "Latvia", "alpha3": "LVA"},
    {"id": 6269, "name": "France", "alpha3": "FRA"},
    {"id": 6271, "name": "Romania", "alpha3": "ROU"},
    {"id": 6272, "name": "Canada", "alpha3": "CAN"},
    {"id": 6911, "name": "Norway", "alpha3": "NOR"},
    {"id": 6963, "name": "Austria", "alpha3": "AUT"},
    {"id": 7738, "name": "England", "alpha3": "GBR"},
    {"id": 7894, "name": "Ukraine", "alpha3": "UKR"},
    {"id": 7952, "name": "Turkey", "alpha3": "TUR"},
    {"id": 7953, "name": "Japan", "alpha3": "JPN"},
    {"id": 7954, "name": "Israel", "alpha3": "ISR"},
    {"id": 8658, "name": "Taiwan", "alpha3": "TWN"},
    {"id": 8659, "name": "South Korea", "alpha3": "KOR"},
    {"id": 8702, "name": "Spain", "alpha3": "ESP"},
    {"id": 9767, "name": "Germany", "alpha3": "DEU"},
    {"id": 10257, "name": "Singapore", "alpha3": "SGP"},
    {"id": 11674, "name": "Hong Kong", "alpha3": "HKN"},
    {"id": 12245, "name": "Thailand", "alpha3": "THA"},
    {"id": 15266, "name": "India", "alpha3": "IND"},
    {"id": 15701, "name": "Italy", "alpha3": "ITA"},
    {"id": 17009, "name": "Australia", "alpha3": "AUS"}
];

export default countriesLocation;
