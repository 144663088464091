import React, { useState, useEffect } from 'react';
import api from './../api/axios';
import { useNavigate } from 'react-router-dom';

const TeamsIntegration = () => {
  const [isConnected, setIsConnected] = useState(false);
  const [accessToken, setAccessToken] = useState('');
  const [teamId, setTeamId] = useState('');
  const [channelId, setChannelId] = useState('');
  const [teams, setTeams] = useState([]);
  const [channels, setChannels] = useState([]);
  const [logMessage, setLogMessage] = useState('');
  const [logPreferences, setLogPreferences] = useState({
    campaignLogs: false,
    performedActions: false
  });

  const navigate = useNavigate();

  const TEAMS_CLIENT_ID = process.env.REACT_APP_TEAMS_CLIENT_ID;
  const TEAMS_TENANT_ID = process.env.REACT_APP_TEAMS_TENANT_ID;
  const TEAMS_REDIRECT_URI = 'https://app.leadgenflow.io/settings';
  const TEAMS_OAUTH_URL = `https://login.microsoftonline.com/${TEAMS_TENANT_ID}/oauth2/v2.0/authorize?client_id=${TEAMS_CLIENT_ID}&response_type=code&redirect_uri=${encodeURIComponent(TEAMS_REDIRECT_URI)}&response_mode=query&scope=User.Read%20ChannelMessage.Send%20offline_access`;

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    
    if (code && !accessToken) {
      api.post('/integrations/teams/auth', { code, redirect_uri: TEAMS_REDIRECT_URI })
        .then(response => {
          const { access_token } = response.data;
          setAccessToken(access_token);
          sessionStorage.setItem('teamsAccessToken', access_token);  // Store token in sessionStorage
          setIsConnected(true);
          navigate('/settings?tab=integrations', { replace: true });
        })
        .catch(error => console.error('Teams OAuth error:', error));
    } else {
      const savedToken = sessionStorage.getItem('teamsAccessToken');
      if (savedToken) {
        setAccessToken(savedToken);
        setIsConnected(true);
        fetchTeams(savedToken);
      }
    }
  }, [navigate, accessToken]);
  
  useEffect(() => {
    if (isConnected && accessToken) {
      fetchTeams(accessToken);
    }
  }, [isConnected, accessToken]);
  
  

  const fetchTeams = (token) => {
    api.get('/integrations/teams/teams', {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(response => setTeams(response.data))
    .catch(error => console.error('Error fetching teams:', error));
  };

  const fetchChannels = (selectedTeamId) => {
    setTeamId(selectedTeamId);
    api.get(`/integrations/teams/${selectedTeamId}/channels`, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response => setChannels(response.data))
    .catch(error => console.error('Error fetching channels:', error));
  };

  const handleConnect = () => {
    window.location.href = TEAMS_OAUTH_URL;
  };

  const handleSendMessage = () => {
    if (!accessToken || !teamId || !channelId) {
      console.error('Teams is not connected or team/channel is not specified');
      return;
    }

    const messageData = {
      teamId,
      channelId,
      text: `Log Message Received: ${logMessage}`,
    };

    api.post('/integrations/teams/sendMessage', messageData, {
      headers: { Authorization: `Bearer ${accessToken}` }
    })
    .then(response => {
      console.log('Message sent to Teams:', response.data);
      setLogMessage('');
    })
    .catch(error => console.error('Error sending message to Teams:', error));
  };

  const handleLogPreferencesChange = (event) => {
    setLogPreferences({
      ...logPreferences,
      [event.target.name]: event.target.checked,
    });
  };

  const handleSavePreferences = () => {
    const preferencesData = {
      teamId,
      channelId,
      logPreferences,
    };

    api.put('/integrations/teams/user/teams-integration', preferencesData)
      .then(response => {
        console.log('Log preferences saved:', response.data);
      })
      .catch(error => console.error('Error saving log preferences:', error));
  };

  return (
    <div className="teams-integration">
      <h3>Microsoft Teams Integration</h3>
      {!isConnected ? (
        <div>
          <p>Connect your Microsoft Teams account to send notifications.</p>
          <button onClick={handleConnect} className="btn btn-primary">
            Connect to Teams
          </button>
        </div>
      ) : (
        <div>
          <p>Microsoft Teams is connected. Try it out, send a test message.</p>

          {/* Select Team */}
          <select onChange={(e) => fetchChannels(e.target.value)} value={teamId}>
            <option value="">Select a Team</option>
            {teams.map((team) => (
              <option key={team.id} value={team.id}>{team.displayName}</option>
            ))}
          </select>

          {/* Select Channel */}
          <select onChange={(e) => setChannelId(e.target.value)} value={channelId} disabled={!teamId}>
            <option value="">Select a Channel</option>
            {channels.map((channel) => (
              <option key={channel.id} value={channel.id}>{channel.displayName}</option>
            ))}
          </select>

          <div>
            <input
              type="text"
              placeholder="Enter log message"
              value={logMessage}
              onChange={(e) => setLogMessage(e.target.value)}
            />
            <button onClick={handleSendMessage} className="btn btn-success">
              Send Log Message to Teams
            </button>
          </div>

          {/* Log Preferences */}
          <div>
            <h4>Select Logs to Send</h4>
            <label>
              <input
                type="checkbox"
                name="campaignLogs"
                checked={logPreferences.campaignLogs}
                onChange={handleLogPreferencesChange}
              />
              Campaign Logs (activated, paused, stopped, etc.)
            </label>
            <label>
              <input
                type="checkbox"
                name="performedActions"
                checked={logPreferences.performedActions}
                onChange={handleLogPreferencesChange}
              />
              Performed Actions (send invite, send message, etc.)
            </label>
            <button onClick={handleSavePreferences} className="btn btn-secondary">
              Save Preferences
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default TeamsIntegration;
