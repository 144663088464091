import React, { useState } from 'react';
import Userfront from '@userfront/toolkit/react';
import api from './../../api/axios'; // Import your axios instance
import './Signup.css'; // Import your custom CSS for styling
import { Link, useNavigate } from 'react-router-dom'; // Import useNavigate for redirection
import axios from 'axios';

const Signup = () => {
  const navigate = useNavigate(); // Hook to handle redirection

  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    organization: '',
    employees: '',
    email: '',
    password: '',
  });
  const [errors, setErrors] = useState({});
  const [touched, setTouched] = useState({});
  const [passwordStrength, setPasswordStrength] = useState(0);
  const [emailExists, setEmailExists] = useState(false); // State to handle email existence

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    // Update touched state for individual fields
    setTouched((prev) => ({
      ...prev,
      [name]: true,
    }));

    // Update password strength on password change
    if (name === 'password') {
      updatePasswordStrength(value);
    }
  };

  const updatePasswordStrength = (password) => {
    let strength = 0;

    // Calculate password strength
    if (password.length >= 8) strength += 20;
    if (/[A-Z]/.test(password)) strength += 20;
    if (/[a-z]/.test(password)) strength += 20;
    if (/[0-9]/.test(password)) strength += 20;
    if (/[!@#$%^&*]/.test(password)) strength += 20;

    setPasswordStrength(strength);
  };

  const validateStepOne = () => {
    let validationErrors = {};

    if (!formData.name) validationErrors.name = 'Name is required.';
    if (!formData.organization) validationErrors.organization = 'Organization is required.';
    if (!formData.employees) validationErrors.employees = 'Number of employees is required.';

    return validationErrors;
  };

  const validateStepTwo = () => {
    let validationErrors = {};

    if (!formData.email) validationErrors.email = 'Email is required.';
    if (!formData.password) {
      validationErrors.password = 'Password is required.';
    } else if (formData.password.length < 8) {
      validationErrors.password = 'Password must be at least 8 characters.';
    } else if (!/[A-Z]/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one uppercase letter.';
    } else if (!/[a-z]/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one lowercase letter.';
    } else if (!/[0-9]/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one number.';
    } else if (!/[!@#$%^&*]/.test(formData.password)) {
      validationErrors.password = 'Password must contain at least one special character.';
    }

    return validationErrors;
  };

  const handleNext = async () => {
    const validationErrors = validateStepOne();
    setErrors(validationErrors);

    // Only proceed if there are no validation errors
    if (Object.keys(validationErrors).length === 0) {
      setStep(2); // Move to Step 2
    }
  };

  const handlePrev = () => {
    setStep(1); // Go back to Step 1
  };

  const checkEmailExists = async (email) => {
    try {
      const response = await api.get('/check-email', {
        params: { email },
      });
      return response.data.exists;
    } catch (error) {
      console.error('Error checking email:', error);
      return false; // Default to false on error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateStepTwo();
    setErrors(validationErrors);

    if (Object.keys(validationErrors).length === 0) {
      // Check if email already exists
      const exists = await checkEmailExists(formData.email);
      if (exists) {
        setEmailExists(true);
        return; // Do not proceed if email already exists
      } else {
        setEmailExists(false);
      }

      console.log('API Key:', process.env.REACT_APP_USERFRONT_API_KEY);


      try {
        // Prepare the payload for the Userfront signup API
        const payload = {
         // method: 'password',
          name: formData.name,
          email: formData.email,
          password: formData.password,
     //     redirect: false,
          options: {
            noSignupEmail: true // Prevents sending the welcome or signup email
          }
        };
    
        // Define options for Axios request
        const options = {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ` + process.env.REACT_APP_USERFRONT_API_KEY
          },
        };
    
        // Sign up the user with Userfront
        const response = await axios.post("https://api.userfront.com/v0/tenants/9nym64vn/auth/create", payload, options);

        console.log("Response: " + JSON.stringify(response));
    
        // Register the user in the database with the profile image URL
        await api.post('/register-user', {
          userId: response.data.userId, // Adjust based on the actual response structure
          name: formData.name,
          email: formData.email,
          organization: formData.organization,
          employees: formData.employees,
          profileImageUrl: response.image, // Adjust based on the actual response structure
        });
    
        // Redirect to the confirmation page with email in query
        navigate(`/confirmation?email=${formData.email}`);
      } catch (error) {
        console.error('Error during registration:', error);
        alert('Registration failed. Please try again.');
      }
      
    }
  };

  return (
    <div className="container-register">
      <div className="row">
        <div className="signup">
          <img className="logo" src="/logo.png" alt="LeadgenFlow Logo" />
          <h1 className="signup-title">Register</h1>
          <form onSubmit={handleSubmit} className="signup-form">
            {step === 1 && (
              <div className="form-step">
                <h2 className="form-subtitle">Step 1: Personal Information</h2>
                <label className="form-label">
                  Name:
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`form-input ${
                      touched.name && errors.name ? 'input-error' : ''
                    }`}
                  />
                </label>
                {touched.name && errors.name && (
                  <span className="error-message">{errors.name}</span>
                )}
                <label className="form-label">
                  Organization:
                  <input
                    type="text"
                    name="organization"
                    value={formData.organization}
                    onChange={handleChange}
                    className={`form-input ${
                      touched.organization && errors.organization
                        ? 'input-error'
                        : ''
                    }`}
                  />
                </label>
                {touched.organization && errors.organization && (
                  <span className="error-message">{errors.organization}</span>
                )}
                <label className="form-label">
                  Number of Employees:
                  <input
                    type="number"
                    name="employees"
                    value={formData.employees}
                    onChange={handleChange}
                    className={`form-input ${
                      touched.employees && errors.employees ? 'input-error' : ''
                    }`}
                  />
                </label>
                {touched.employees && errors.employees && (
                  <span className="error-message">{errors.employees}</span>
                )}
                <button type="button" onClick={handleNext} className="form-button">
                  Next
                </button>
              </div>
            )}
            {step === 2 && (
              <div className="form-step">
                <h2 className="form-subtitle">Step 2: Account Security</h2>
                <button type="button" onClick={handlePrev} className="back-button">
                  <span className="back-icon">&#8592;</span> Previous Step
                </button>
                <label className="form-label">
                  Email:
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`form-input ${
                      touched.email && (errors.email || emailExists) ? 'input-error' : ''
                    }`}
                  />
                </label>
                {touched.email && errors.email && (
                  <span className="error-message">{errors.email}</span>
                )}
                {emailExists && (
                  <span className="error-message">This email address already exists.</span>
                )}
                <label className="form-label">
                  Password:
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleChange}
                    className={`form-input ${
                      touched.password && errors.password ? 'input-error' : ''
                    }`}
                  />
                </label>
                {touched.password && errors.password && (
                  <span className="error-message">{errors.password}</span>
                )}
                <small className="password-requirements">
                  Password must be at least 8 characters, include an uppercase
                  letter, a lowercase letter, a number, and a special character.
                </small>

                {/* Password Strength Indicator */}
                <div className="password-strength">
                  <div
                    className="password-strength-bar"
                    style={{
                      width: `${passwordStrength}%`,
                      backgroundColor:
                        passwordStrength < 40
                          ? 'red'
                          : passwordStrength < 80
                          ? 'orange'
                          : 'green',
                    }}
                  />
                </div>

                <button type="submit" className="form-button">
                  Register
                </button>
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Signup;
