import React, { useState } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import InvoicingDetailsForm from './../pages/Subscription/InvoicingDetailsForm'; // Import your invoicing form
import api from './../api/axios'; // Import your axios instance
import './CheckoutForm.css'; // Import your CSS file

const CheckoutForm = ({ email, onSuccess, showBasicPlans, activeSubscriptions }) => {
  const stripe = useStripe();
  const [step, setStep] = useState(1); // Track the current step
  const [selectedPlanType, setSelectedPlanType] = useState('basic'); // Default to 'basic'
  const [billingCycle, setBillingCycle] = useState('monthly'); // Track billing cycle
  const [loading, setLoading] = useState(false);
  const [invoicingDetails, setInvoicingDetails] = useState({
    companyName: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    postalCode: '',
    state: '',
    country: '',
    vatNumber: '',
  });
  const [error, setError] = useState('');
  
  const activePlanIds = activeSubscriptions.map(sub => sub.planId);

  // Plan Prices
  const planPrices = {
    basic: { monthly: '€40', yearly: '€400' },
    team: { monthly: '€80', yearly: '€800' },
  };

  // Handle plan type selection
  const handlePlanTypeSelect = (type) => {
    setSelectedPlanType(type);
    setStep(2); // Proceed to invoicing details
  };

  // Handle toggle between Monthly/Annually
  const handleBillingCycleToggle = () => {
    const newCycle = billingCycle === 'monthly' ? 'yearly' : 'monthly';
    setBillingCycle(newCycle);
  };

  // Save Invoicing Details
  const handleSaveInvoicingDetails = () => {
    if (!invoicingDetails.companyName || !invoicingDetails.addressLine1 || !invoicingDetails.city || !invoicingDetails.postalCode || !invoicingDetails.country) {
      setError('Please fill in all required fields.');
      return;
    }
    setError('');
    setStep(3);
  };

  // Handle form submission to Stripe
  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    const selectedPlan = `${selectedPlanType}_${billingCycle}`;

    try {
      const response = await api.post('/create-checkout-session', {
        email,
        plan: selectedPlan,
        invoicingDetails,
      });

      const sessionId = response.data.sessionId;
      const { error } = await stripe.redirectToCheckout({ sessionId });

      if (error) {
        console.error('Error redirecting to Checkout:', error);
        alert('Subscription failed. Please try again.');
      }
    } catch (error) {
      console.error('Error processing subscription:', error);
      alert('Subscription failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="checkout-form-container">
      {/* Progress Bar */}
      <div className="progress-bar-container">
        {[1, 2, 3].map((s) => (
          <div
            key={s}
            className={`progress-step ${step >= s ? 'active' : ''}`}
            onClick={() => setStep(s)}
          >
            Step {s}: {s === 1 ? 'Select Plan' : s === 2 ? 'Invoicing Details' : 'Review & Confirm'}
          </div>
        ))}
      </div>

      {/* Step 1: Plan Selection */}
      {step === 1 && (
        <div className="plan-selection">
          <div className="row">
            {['basic', 'team'].map((type) => (
              <div className="col-md-6" key={type}>
                <div className="card plan-card text-center">
                  <div className="card-body">
                    <i className={`fa fa-${type === 'team' ? 'users' : 'user'} plan-icon`}></i>
                    <h5 className="card-title">{type.charAt(0).toUpperCase() + type.slice(1)} Plan</h5>
                    <p>
                      {billingCycle === 'monthly' ? planPrices[type].monthly : planPrices[type].yearly}
                    </p>
                    <div className="toggle-switch">
                      <span>Monthly</span> {/* Text on the left */}
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={billingCycle === 'yearly'}
                          onChange={handleBillingCycleToggle}
                        />
                        <span className="slider round"></span>
                      </label>
                      <span>Annually (2 months free)</span> {/* Text on the right */}
                    </div>
                    <button className="btn btn-primary" onClick={() => handlePlanTypeSelect(type)}>
                      Select {type.charAt(0).toUpperCase() + type.slice(1)}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Step 2: Invoicing Details */}
      {step === 2 && (
        <div className="invoicing-details">
          <h3>Enter Invoicing Details</h3>
          <InvoicingDetailsForm
            invoicingDetails={invoicingDetails}
            setInvoicingDetails={setInvoicingDetails}
            handleSaveInvoicingDetails={handleSaveInvoicingDetails}
            error={error}
          />
        </div>
      )}

      {/* Step 3: Checkout Summary */}
      {step === 3 && (
        <div className="checkout-summary">
          <h3>Review and Confirm</h3>
          <p><strong>Plan:</strong> {selectedPlanType.charAt(0).toUpperCase() + selectedPlanType.slice(1)} ({billingCycle.charAt(0).toUpperCase() + billingCycle.slice(1)})</p>
          <p>
            <strong>Price:</strong> {billingCycle === 'monthly' ? planPrices[selectedPlanType].monthly : planPrices[selectedPlanType].annually}
          </p>
          
          <div className='checkout-summary-invoice'>
            <h5>Invoice Details</h5>
            <p>
              {invoicingDetails.companyName} <br/>
              {invoicingDetails.addressLine1} {invoicingDetails.addressLine2 && `, ${invoicingDetails.addressLine2}`} <br/>
              {invoicingDetails.postalCode} {invoicingDetails.city} <br />
              {invoicingDetails.state && <div>{invoicingDetails.state} <br /></div>}
              {invoicingDetails.country}
            </p>
            {invoicingDetails.vatNumber && <div><p><strong>VAT Number:</strong> {invoicingDetails.vatNumber}</p></div>}
          </div>

          <p><em>By confirming, you will be securely redirected to Stripe Checkout.</em></p>

          <button
            className="btn btn-primary"
            onClick={handleSubmit}
            disabled={loading || !stripe}
          >
            {loading ? 'Processing...' : 'Complete Purchase'}
          </button>
        </div>
      )}
    </div>
  );
};

export default CheckoutForm;
